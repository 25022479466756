import React from "react";
import { Navbar, Container, Button } from "react-bootstrap";
import brand from "../images/brand.png";

const Nav = ({ connect, disconnect, active }) => {
  return (
    <Navbar className="shadow-lg" bg="dark" variant="dark">
      <Container>
        <Navbar.Brand href="#home">
          <img src={brand} alt="brand logo" height="70" />
        </Navbar.Brand>
        <Navbar.Toggle />
        <Navbar.Collapse className="justify-content-end">
          {active ? (
            <Button onClick={disconnect} variant="outline-danger">
              Disconnect
            </Button>
          ) : (
            <Button onClick={connect} variant="outline-success">
              Connect Wallet
            </Button>
          )}
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default Nav;
